<style scoped>
.section-event-category {
  background: #fff;
  padding: 40px 40px 10px;
  position: relative;
}
.category-listing-wrap .event-category-wrapper{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0px;
  min-height: 100px;
  margin: 0 -15px;
}
.section-event-category .section-content.category-listing-wrap ul li{
  width: 14.5%;
  display: inline-block;
  vertical-align: middle;
  margin: 0 15px 30px;
}
.section-event-category .section-content.category-listing-wrap ul li a{
  width: 100%;
  height: 100%;
}
.section-event-category .section-content ul li a .category-wrap {
  overflow: hidden;
  height: 100%;
  border-radius: 8px;
  position: relative;
}
.section-event-category .section-content ul li img{
  -moz-transition:all .3s ease-in-out 0s;
  -webkit-transition:all .3s ease-in-out 0s;
  -ms-transition:all .3s ease-in-out 0s;
  -o-transition:all .3s ease-in-out 0s;
  transition:all .3s ease-in-out 0s;
  border-radius:8px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.section-event-category .section-content ul li a:hover img {
  transform: scale(1.2);
  filter: blur(3px);
}
.section-event-category .section-content ul li #error-image + span.cate-name {
  display: block;
  content:'';
  left:0;
  width:100%;
  background:rgb(244 156 0/45%);
  position: absolute;
  color: #fff;
  text-align: center;
  text-decoration: none;
  top: 0;
  bottom: 0;
  text-transform: uppercase;
  height: 100%;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}
.section-event-category .section-content ul li.category-1:hover .category-wrap #error-image + span.cate-name{
  background:rgb(244 156 0/80%)
}
.section-event-category .section-content ul li span.cate-name {
  display: block;
  content:'';
  left:0;
  width:100%;
  background:rgba(0,0,0,.4);
  position: absolute;
  color: #fff;
  text-align: center;
  text-decoration: none;
  top: 0;
  bottom: 0;
  text-transform: uppercase;
  height: 100%;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}
.section-event-category .section-content ul li a .category-wrap .cate-name label{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  font-size: 20px;
  width: 80%;
  line-height: 26px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  word-break: break-word;
}
.innerpage-banner{
  height:150px;
  position: relative;
  background-image:url('~@/../public/images/share-action.webp');
}
.innerpage-banner:after{
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: 0 0;
  background-color: rgba(0,0,0,.5);
}
.innerpage-banner img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.innerpage-banner .section-header{
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex:1;
  flex:1;
  height:100%;
  max-width:100%;
  position:absolute;
  width:100%;
  justify-content:center;
  top: 0;
  flex-direction: column;
  padding: 0 10px;
}
.innerpage-banner .section-header h1{
  color:#fff;
  font-family:'Heebo', sans-serif !important;
  font-weight:700;
  font-size: 36px;
  text-align: center;
  margin-bottom: 8px;
  z-index: 1;
}
.innerpage-banner .section-header .search-wrapper{
  position: relative;
  z-index: 2;
}
.dis-none{
  display:none!important
}
.pindrop_cssloader_main:before{
  content:'';
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background-color:rgba(255,255,255,.7);
  z-index:1
}
.pindrop_cssloader_main{
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  right:0;
  background-color:transparent;
  z-index:1;
  align-items:center;
  justify-content:center;
  display:flex
}
.lds-ellipsis{
  display:inline-block;
  position:relative;
  width:80px;
  height:80px;
  z-index:2
}
.lds-ellipsis div{
  position:absolute;
  top:33px;
  width:13px;
  height:13px;
  border-radius:50%;
  background:#f08100;
  animation-timing-function:cubic-bezier(0,1,1,0)
}
.lds-ellipsis div:nth-child(1){
  left:8px;
  animation:lds-ellipsis1 .6s infinite
}
.lds-ellipsis div:nth-child(2){
  left:8px;
  animation:lds-ellipsis2 .6s infinite
}
.lds-ellipsis div:nth-child(3){
  left:32px;
  animation:lds-ellipsis2 .6s infinite
}
.lds-ellipsis div:nth-child(4){
  left:56px;
  animation:lds-ellipsis3 .6s infinite
}
.search-wrapper .header-search{
  position: relative;
}
.search-wrapper .header-search .search-btn{
  position:absolute;
  left:15px;
  top:13px;
  border:0;
  padding:0;
  color:#fff;
  line-height:14px;
  width:17px;
  height:17px;
  background-image:url('~@/../public/images/header-search-icon.webp');
  background-repeat:no-repeat;
  background-position:center;
  background-size:17px;
  background-color: transparent;
}
@media (min-width: 768px){
  .section-event-category .section-header h1 {
    font-size: 27px;
    line-height: 42px;
    margin: 0 0 20px;
    padding: 0 0 0 15px;
  }
}
@media (min-width: 992px){
  .section-event-category .container{
    max-width:100%
  }
}
@media (min-width: 1024px){
  .section-event-category {
    margin: 0 auto;
    max-width: calc(1368px + 50rem);
  }
  .section-event-category .section-header h1 {
    font-size: 32px;
    line-height: 48px;
    padding: 0 0 0 15px;
  }
  .innerpage-banner{
    height:180px
  }
}
@media (max-width: 1600px){
  .section-event-category .section-content.category-listing-wrap ul li{
    width: 17.5%;
  }
}
@media (max-width: 1280px){
  .section-event-category .section-content.category-listing-wrap ul li{
    width: 21.5%;
  }
  .innerpage-banner .container{
    max-width:100%
  }
}
@media (max-width: 1200px){
  .search-wrapper .header-search .search-btn{
    left: 10px;
    top: 12px;
  }
}
@media (max-width: 1199px){
  .search-wrapper .header-search .search-btn{
    width: 15px;
    height: 15px;
    background-size: 15px;
    top: 12px;
    left: 10px;
  }
}
@media (max-width: 1024px){
  .innerpage-banner .section-header h1{
    font-size:32px
  }
  .section-event-category .section-content ul li a .category-wrap .cate-name label{
    width: 85%;
  }
}
@media (max-width: 991px){
  .section-event-category{
    padding: 40px 15px 10px;
  }
  .section-event-category .section-content ul li a .category-wrap .cate-name label{
    font-size: 16px;
    line-height: 22px;
  }
  .section-event-category .section-content.category-listing-wrap ul li{
    width: 20.5%;
  }
}
@media (max-width: 768px){
  .section-event-category .section-content.category-listing-wrap ul li{
    width: 27.5%;
  }
  .innerpage-banner .section-header h1{
    font-size:24px;
    margin-bottom: 5px;
  }
}
@media (max-width: 767px){
  .section-event-category .container {
    max-width: 100%
  }
  .section-event-category .section-header h1 {
    font-size: 24px;
    line-height: 36px;
    margin: 0 0 20px;
    padding: 0 0 0 15px;
  }
  .section-event-category .section-header h2:before {
    height: 18px;
  }
  .section-event-category .section-content ul li a .category-wrap .cate-name label {
    font-size: 18px;
  }
  .innerpage-banner .container{
    max-width:100%
  }
}
@media (max-width: 575px){
  .innerpage-banner .section-header h1{
    font-size: 22px;
  }
  .section-event-category .section-content.category-listing-wrap ul li {
    width: 41.5%;
    margin-bottom: 20px;
  }
  .search-wrapper{
    width: 100%;
    display: flex;
  }
  .search-wrapper .header-search{
    width: 100%;
  }
  .section-event-category{
    padding: 35px 15px 15px;
  }
}
@media (max-width: 480px){
  .section-event-category{
    padding: 30px 15px 10px;
  }
}
@media (max-width: 400px){
  .innerpage-banner .section-header h1{
    font-size: 20px;
  }
  .section-event-category .section-content.category-listing-wrap ul li {
    width: 100%;
  }
  .section-event-category .section-content ul li a .category-wrap .cate-name label {
    font-size: 22px;
    line-height: 28px;
  }
}
</style>
<template>
  <section class="innerpage-banner">
    <!-- <v-lazy-image src="images/share-action.webp" alt="image" width="1920" height="235"></v-lazy-image> -->
    <div class="section-header">
      <!-- <h1 v-if="this.$route.query.type != 'THEATER'">{{ this.$route.query.type ? this.$route.query.type + 'S' : 'All Categories' }}</h1> -->
      <h1 v-if="this.$route.params.categorieName !== 'theater'">{{ this.$route.params.categorieName.toUpperCase() + 'S'}}</h1>
      <h1 v-else-if="this.$route.params.categorieName === 'theater'">{{ this.$route.params.categorieName.toUpperCase() }}</h1>
      <h1 v-else>{{ this.$route.query.type ? this.$route.query.type : 'All Categories' }}</h1>
      <div class="search-wrapper mobile-search-icon-j">
        <div class="hero-search header-search">
          <GlobalSearchAutocomplete/>
          <button v-on:click="searchEvents" class="search-btn" type="submit"></button>
        </div>
      </div>
      <BannerSubText />
    </div>
  </section>
  <section class="section-event-category category-wrap">
    <div class="container">
      <div class="section-content category-listing-wrap">
        <ul class="event-category-wrapper">
          <div class="pindrop_cssloader_main" v-bind:class="{ 'dis-none': areCategoriesLoaded}">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <li class="category-1" v-for="(item) in eventCategories" :key="item">
            <router-link :to="`/performers/${item.slug}/${item.categoryId}`">
              <div class="category-wrap">
                <!-- <img onerror="this.src='images/event-category-1.webp';this.id='error-image';this.onerror='';" :src="'https://litseats-images.s3.us-east-2.amazonaws.com/'+ this.$route.query.type + '/' + item.slug + 'dsf.png'" alt="image" width="266" height="266"> -->
                <img onerror="this.src= assetsUrl +'images/event-category-1.webp';this.id='error-image';this.onerror='';" id='error-image' :src="assetsUrl + 'images/event-category-1.webp'" alt="image" width="266" height="266">
                <span class="cate-name" href="javascript:void(0)"><label>{{ item.name }}</label></span>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
// import $ from 'jquery'
import axios from 'axios'
import VLazyImage from 'v-lazy-image'
import BannerSubText from './../../components/layouts/BannerSubText.vue'
import GlobalSearchAutocomplete from '../../views/autocomplete/GlobalSearchAutocomplete'
/* eslint-disable */
export default {
  name: 'Categories',
  beforeCreate () {
    var title = ''
    if (this.$route.query.type) {
      title = this.$route.query.type !== 'THEATER' ? this.$route.query.type + 'S' : this.$route.query.type
    } else {
      title = 'Event Categories'
    }
    document.title = title + ' | ' + process.env.VUE_APP_NAME
  },
  components: {
    VLazyImage,
    BannerSubText,
    GlobalSearchAutocomplete
  },
  data: function () {
    return {
      eventCategories: [],
      areCategoriesLoaded: false,
      assetsUrl: process.env.VUE_APP_ASSETS_URL,
    }
  },
  watch: {
    $route (to, from) {
      this.eventCategories = []
      this.areCategoriesLoaded = false
      this.getCategories()
    }
  },
  mounted () {
    this.getCategories()
  },
  methods: {
    getCategories: function (event) {
      axios.get(process.env.VUE_APP_API_URL + 'getCategories', {
        params: {
          // eventType: this.$route.query.type
          eventType: this.$route.params.categorieName
        }
      }).then(response => {
        if (response.status === 200) {
          this.areCategoriesLoaded = true
          this.eventCategories = response.data.data.categories
        }
      })
    }
  }
}
</script>
